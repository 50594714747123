import { WebsiteCommonModule } from './../../../common/common.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MetaModule, MetaService } from '@ngx-meta/core';
import { NgxCaptchaModule } from 'ngx-captcha';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { IconSpriteModule } from 'ng-svg-icon-sprite';

import { registerLocaleData } from '@angular/common';
import localeCL from '@angular/common/locales/es-CL';
registerLocaleData(localeCL);

import { routes } from './app.routing';
import { AppComponent } from './app.component';

import { CustomMaterialModule } from '../../../common/material.module';
import { ErrorPageComponent } from './error-page/error-page.component';

import { HomeComponent } from './home/home.component';
import { SectionDocIssueanceComponent } from './home/section-doc-issueance/section-doc-issueance.component';
import { SectionSliderTabComponent } from './home/section-slider-tab/section-slider-tab.component';
import { FeaturesComponent } from './features/features.component';
import { PriceComponent } from './price/price.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { BlockLogoComponent } from './shared/block-logo/block-logo.component';
import { ContactWrapperComponent } from './contact/contact-wrapper.component';
import { ContactAndRequestQuoteComponent } from './contact-and-request-quote/contact-and-request-quote.component';
import { ContactsComponent } from '../../../common/contacts/contacts.component';
import { ContactSupportComponent } from '../../../common/contact-support/contact-support.component';
import { ContactSupportWrapperComponent } from './contact-support-wrapper/contact-support-wrapper.component';
import { SectionPreviewMachineComponent } from './contact-support-wrapper/section-preview-machine/section-preview-machine.component';
import { DevelopersComponent } from './developers/developers.component';
import { CodeHighlightingComponent } from './shared/code-highlighting/code-highlighting.component';
import { PaymentFormsWrapperComponent } from './payment-forms-wrapper/payment-forms-wrapper.component';

// import { RaygunErrorHandler } from '../../../common/app.raygun.setup';
import { IntegrationsComponent } from './integrations/integrations.component';
import { ShopifyComponent } from './integrations-shopify/shopify.component';
import { WhmcsComponent } from './integrations-whmcs/whmcs.component';
import { WoocommerceComponent } from './integrations-woocommerce/woocommerce.component';
import { PrestashopComponent } from './integrations-prestashop/prestashop.component';
import { IntegrationsProfsComponent } from './integrations/templates/integrations-profs/integrations-profs.component';
import { IntegrationsModsComponent } from './integrations/templates/integrations-mods/integrations-mods.component';
import { IntegrationsIntegrationComponent } from './integrations/templates/integrations-integration/integrations-integration.component';
import { IntegrationsPluginsComponent } from './integrations/templates/integrations-plugins/integrations-plugins.component';
import { IntegrationsJumpsellerComponent } from './integrations-jumpseller/integrations-jumpseller.component';
import { SectionPointOfSaleComponent } from './home/section-point-of-sale/section-point-of-sale.component';
import { SectionTrainingComponent } from './home/section-training/section-training.component';
import { SectionPriceComponent } from './home/section-price/section-price.component';
import { CardPriceComponent } from './home/section-price/card-price/card-price.component';
import { CardOptionsComponent } from './home/section-price/card-options/card-options.component';
import { CardTotalComponent } from './home/section-price/card-total/card-total.component';

// dialogs
import { DialogCustomMenuComponent } from './shared/dialogs/dialog-custom-menu/dialog-custom-menu.component';
import { PopUpContactsComponent } from './shared/pop-up-contacts/pop-up-contacts.component';

// pipe
import { CurrencyLocalePipe } from './currency.pipe';

@NgModule({
    declarations: [
        AppComponent,
        ErrorPageComponent,
        HomeComponent,
        SectionDocIssueanceComponent,
        SectionSliderTabComponent,
        FeaturesComponent,
        PriceComponent,
        HeaderComponent,
        FooterComponent,
        BlockLogoComponent,
        ContactsComponent,
        ContactWrapperComponent,
        ContactAndRequestQuoteComponent,
        ContactSupportComponent,
        ContactSupportWrapperComponent,
        SectionPreviewMachineComponent,
        DevelopersComponent,
        CodeHighlightingComponent,
        PaymentFormsWrapperComponent,
        SectionPointOfSaleComponent,
        SectionTrainingComponent,
        SectionPriceComponent,
        CardPriceComponent,
        CardOptionsComponent,
        CardTotalComponent,
        // integrations
        IntegrationsComponent,
        ShopifyComponent,
        WhmcsComponent,
        WoocommerceComponent,
        PrestashopComponent,
        IntegrationsProfsComponent,
        IntegrationsModsComponent,
        IntegrationsIntegrationComponent,
        IntegrationsPluginsComponent,
        IntegrationsJumpsellerComponent,
        // pipe
        CurrencyLocalePipe,
        // dialogs
        DialogCustomMenuComponent,
        PopUpContactsComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'openfactura' }),
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
        BrowserAnimationsModule,
        MetaModule.forRoot(),
        CustomMaterialModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgxCaptchaModule,
        TransferHttpCacheModule,
        DeferLoadModule,
        IconSpriteModule,
        WebsiteCommonModule
    ],
    entryComponents: [
        DialogCustomMenuComponent
    ],
    providers: [
        MetaService,
        CurrencyLocalePipe
        // {
        //     provide: ErrorHandler,
        //     useClass: RaygunErrorHandler
        // }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
