import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { ProfsData } from '../integrations/templates/integrations-profs/integrations-profs.component';
import { URL } from '../../environments/environment';
import { MetaService } from '@ngx-meta/core';

@Component({
    selector: 'app-whmcs',
    templateUrl: './whmcs.component.html'
})
export class WhmcsComponent implements OnInit, OnDestroy {
    public URL = URL;

    public profsData: ProfsData = {
        subTitle: 'BENEFICIOS ExclusivOS',
        titleHtml: '<h2 class="title2">Invierte menos tiempo en la <br>' +
            '<span class="underline-bg"><b>facturación de tu negocio</b></span>' +
            '</h2>',
        automatization: {
            subTitle: 'DOS ALTERNATIVAS DE EMISIÓN',
            titleHtml: '<b>Emisión automática o manual</b> de boletas y facturas electrónicas.',
            imageUrl: '/assets/images/integrations/home/profs/automatization',
            iconName: 'hand'
        },
        issuance: {
            subTitle: 'Envío automático de documentos',
            titleHtml: '<b>Al finalizar la venta, las boletas o facturas</b> ' +
                'serán enviadas al instante a tus clientes.',
            imageUrl: '/assets/images/integrations/shopify/issuance',
            iconName: 'documents'
        },
        share: {
            subTitle: 'SIN LÍMITES DE EMISIÓN',
            titleHtml: '<b>No existe límites en la cantidad de</b> ' +
                'documentos tributarios que podrás emitir.',
            imageUrl: '',
            iconName: 'limitation'
        },
        sii: {
            subTitle: 'CONVIERTE LAS BOLETAS EN FACTURAS',
            titleHtml: '<b>Permite a tus clientes convertir</b> las boletas emitidas en facturas electrónicas',
            imageUrl: '/assets/images/integrations/shopify/sii',
            // TODO: add correct icon here
            iconName: 'converter'
        }
    };

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private readonly _meta: MetaService
    ) {
    }

    ngOnInit() {
        this.document.body.classList.add('js-integrations');

        this._meta.setTitle('Integración WHMCS Factura Electrónica | Openfactura');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'Integra el addon de Openfactura en tu ecommerce en WHMCS y automatiza la emisión de boleta electrónica o deja que tus clientes los emitan por ti');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'factura electrónica en whmcs, facturación electrónica en whmcs, factura electrónica para ecommerce, facturación electrónica para ecommerce, factura electronica integracion, facturacion electronica, factura electronica');
        this._meta.setTag('author', 'OpenFactura');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.OPENFACTURA_WEBSITE + '/assets/images/integrations/whmcs/integrations_whmcs.png');
        this._meta.setTag('og:image:width', '1200');
        this._meta.setTag('og:image:height', '1200');
        this._meta.setTag('og:image:alt', 'Integración WHMCS Factura Electrónica | Openfactura');
        this._meta.setTag('og:localte', 'es_CL');
    }

    ngOnDestroy(): void {
        this.document.body.classList.remove('js-integrations');
    }

    public goToUrl(url: string): void {
        window.open(url, '_blank');
    }

}
